<template>
<v-card elevation="24" class="mb-15">
  <v-app-bar flat dark color="primary" dense>
      <v-toolbar-title>
          <v-icon>{{icon}}</v-icon>
          {{title}}
      </v-toolbar-title>
          <slot name="headerLeft"></slot>
        <v-spacer></v-spacer>
          <slot name="headerRight"></slot>
  </v-app-bar>
  <slot></slot>
</v-card>
</template>


<script>

  export default {
    name: 'pageCard',
    props: {
        title: String,
        icon: String
    },
    components: {
      // eslint-disable-next-line vue/no-unused-components
      
    },
    mixins: [],
    data () {
        return {}
    }
  }
</script>