<template>
  <v-card class="CustomerReport" >
    <v-card-title class="pb-0">{{value.LAST_NAME}}, {{value.FIRST_NAME}} {{value.MIDDLE}}</v-card-title>
    <v-container class="ml-0 mr-0" fluid>
      <v-row>
        <v-col cols="12" md="4" lg="4" class="pt-0">
          <v-card>
            <v-card-text>
              <v-container class="altRow">
                <v-row >
                  <v-col v-bind="custInfo.keyProps">DOB: </v-col>
                  <v-col v-bind="custInfo.recordProps">{{formatDate(value.D_O_B)}}</v-col>
                </v-row>
                <v-row>
                  <v-col v-bind="custInfo.keyProps">Race: </v-col>
                  <v-col v-bind="custInfo.recordProps">{{value.RACE}}</v-col>
                </v-row>
                <v-row>
                  <v-col v-bind="custInfo.keyProps">Address: </v-col>
                  <v-col v-bind="custInfo.recordProps">
                    {{value.ADDRESS1}}<br/>
                    {{value.ADDRESS2}}<br v-if="value.ADDRESS2"/>
                    {{value.CITY}}, {{value.STATE}} {{value.ZIPCODE}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-bind="custInfo.keyProps">Phone: </v-col>
                  <v-col v-bind="custInfo.recordProps">{{formatPhone(value.PHONE1)}}</v-col>
                </v-row>
                <v-row>
                  <v-col v-bind="custInfo.keyProps">Cell Phone: </v-col>
                  <v-col v-bind="custInfo.recordProps">{{formatPhone(value.CELLPHONE)}}</v-col>
                </v-row>
                <v-row>
                  <v-col v-bind="custInfo.keyProps">SSN: </v-col>
                  <v-col v-bind="custInfo.recordProps">{{value.SOC_NUM}}</v-col>
                </v-row>
                <v-row>
                  <v-col v-bind="custInfo.keyProps">Email: </v-col>
                  <v-col v-bind="custInfo.recordProps">{{value.EMAILADDR}}</v-col>
                </v-row>
                <v-row>
                  <v-col>Comment: <span style="display: inline; white-space: pre-wrap;" class="bold">{{value.COMMENT}}</span></v-col>
                </v-row>                     
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" lg="8" class="pa-0">
          <v-container class="ml-0 pl-0 pt-0">
            <v-row dense>
              <v-col :class="{'d-inline-flex':!isMobile}" >
                <v-card :loading = "defBonds.loading" class="overflow-x-auto">
                  <v-card-title class="pb-0">Bonds as Defendant</v-card-title>
                  <m-table :headers = "defBonds.headers" :data = "defBonds.data" @rowClick="openBondDialog"></m-table>
                  <!-- <v-card-text>{{defBonds}}</v-card-text> -->
                </v-card>
              </v-col>
              </v-row><v-row><v-col :class="{'d-inline-flex':!isMobile}"   >
                <v-card :loading = "indBonds.loading" class="overflow-x-auto">
                  <v-card-title class="pb-0">Bonds as Indemnitor</v-card-title>
                  <m-table :headers = "indBonds.headers" :data = "indBonds.data" @rowClick="openBondDialog"></m-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col><all-data-panel v-model="value"></all-data-panel></v-col>
      </v-row>
    </v-container>
    <m-dialog v-model="bondDialog.visible" title="Bond">
      <bond-report v-model="bondDialog.data"></bond-report>
    </m-dialog>

  </v-card>
</template>

<script>
  import MTable from './MTable.vue'
  import BondReport from './BondReport.vue'
  import apiMixin from '../mixins/apiMixin'
  import formatMixin from '../mixins/formatMixin'
  import overLoading from '../mixins/overLoading'




  export default {
    name: 'customer',
    props: ['props', 'value'],
    mixins: [apiMixin, formatMixin, overLoading],
    components: {
      // eslint-disable-next-line vue/no-unused-components
      MTable,
      BondReport
    },
    data () {
        return {
          bondDialog: {
            visible: false,
            data: {},
          },
          custInfo: {
            keyProps:   {cols: "4"},
            recordProps: {cols: "6", class:"bold"}
          },
          defBonds: {
            loading: false,
            data: null,
            headers: [
              {label: "Status",         key: "DISP",          type: "text"},
              {label: "Agent",          key: "AGENT",         type: "text"},
              {label: "Exec Date",      key: "DATE_EXE",      type: "date"},
              {label: "Pre",            key: "ALPHA_PRE",     type: "text"},
              {label: "Number",         key: "POWER",         type: "text"},
              {label: "Liability",      key: "BOND_AMT",      type: "currency"},
              {label: "Case Number",    key: "CASENUMBER",    type: "text"},
              {label: "Court",          key: "COURT",         type: "text"},
              {label: "County",         key: "COUNTY",        type: "text"},
              {label: "Forf",           key: "Y_FORFIT",      type: "flag",    color: "red"}
            ],
          },
          indBonds: {
            loading: false,
            data: null,
            headers: [
              {label: "Status",         key: "DISP",        type: "text"},
              {label: "Agent",          key: "AGENT",       type: "text"},
              {label: "Exec Date",      key: "DATE_EXE",    type: "date"},
              {label: "Pre",            key: "ALPHA_PRE",   type: "text"},
              {label: "Number",         key: "POWER",       type: "text"},
              {label: "Liability",      key: "BOND_AMT",    type: "currency"},
              {label: "Case Number",    key: "CASENUMBER",  type: "text"},
              {label: "Court",          key: "COURT",       type: "text"},
              {label: "County",         key: "COUNTY",      type: "text"},
              {label: "Last Name",      key: "LAST_NAME",   type: "text"},
              {label: "First Name",     key: "FIRST_NAME",  type: "text"}
            ],
          }
        }
    },
    computed: {

    },
    watch: {
      bondDialog (val) {
          this.bondDialog.data = (val.visible) ? this.bondDialog.data :  null
      },      
      value: function(val) {
        this.getDefBonds()
        this.getIndBonds()
        this.$emit('input', val)
      }
    },
    created() {
      this.getIndBonds()
      this.getDefBonds()
    },
    methods: {
      openBondDialog (bond) {
        this.bondDialog.data = bond
        this.bondDialog.visible = true
      },
      async getIndBonds () {
        this.indBonds.loading = true
        this.apiReadBondsOnInd(this.value.CUST_NUM).then((response) => {
          this.indBonds.data = response.data
          this.indBonds.loading = false
        })
      },
      async getDefBonds () {
          this.defBonds.loading = true
          console.log("getting defendant bonds")
          var payload =  {
              where: {
                  CUST_NUM : this.value.CUST_NUM,
              },
              table: "bond",
              offset: 0,
              rowCount: 5000
          }
          
          // this.data = await this.apiRead(payload)
          this.apiRead(payload).then((response) => {
              this.defBonds.data = response.data
              this.defBonds.loading = false
          })
      },
    }
  }
</script>

<style>
.CustomerReport .bold {
  font-weight: bold;
}

.altRow .row:nth-of-type(odd) {
   background-color: #d7e1ec; 
 }
</style>