<template>
    <v-card >
        <v-card-title>{{value.ALPHA_PRE}} {{value.POWER}} -  {{value.LAST_NAME}}, {{value.FIRST_NAME}}</v-card-title>

        <v-card-text>
            <v-container >
                <v-row><v-col>
                    <v-container v-if="isMobile" class="altRow" >
                        <v-row v-for="(bHeader, bKey) in flattenedRows" :key="bKey" dense>
                            <v-col>{{bHeader.label}}:</v-col>
                            <v-col style="font-weight: bold;">{{value[bHeader.key]}}</v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else>
                        <v-row v-for="(row, rowInd) in rows" :key="rowInd" dense>
                        <v-col v-for="(col, colInd) in row" :key="colInd">
                            <v-textarea v-if="col.inputType=='textArea'" v-bind="col" outlined readonly :value="dispData[col.key]"></v-textarea>
                            <v-checkbox v-else-if="col.inputType=='Boolean'" v-bind="col" outlined readonly :value="dispData[col.key]"></v-checkbox>
                            <v-text-field v-else :label="col.label" outlined dense readonly v-model="dispData[col.key]"></v-text-field>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-col>
                </v-row>
                <v-row>
                <v-col>
                    <v-card :loading="customers.loading" class="overflow-x-auto">
                        <v-card-title class="pb-0">Customers On Bond</v-card-title>
                        <v-card-text><m-table :headers = "customers.headers" :data = "customers.data" @rowClick="openCustomer"></m-table></v-card-text>                      
                    </v-card>
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <all-data-panel v-model="value"></all-data-panel>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <m-dialog v-model="customer.dialog" title="Customer">
            <customer-report v-model="customer.data"></customer-report>
        </m-dialog>
    </v-card>
</template>

<script>
    import apiMixin from '../mixins/apiMixin'
    import formatMixin from '../mixins/formatMixin'
    import overLoading from '../mixins/overLoading'




  export default {
    name: 'bondReport',
    props: ['value'],
    components: {
      // eslint-disable-next-line vue/no-unused-components
      
    },
    mixins: [apiMixin, formatMixin, overLoading],
    data () {
        return {
            cleanedData: {},
            customer: {
                dialog: false,
                data: null
            },
            customers: {
                headers: [
                    {label: "Last Name",    key: "LAST_NAME",   type: "text"},
                    {label: "First Name",   key: "FIRST_NAME",  type: "text"},
                    {label: "Middle Name",  key: "MIDDLE",      type: "text"},
                    {label: "DOB",          key: "D_O_B",       type: "date"},
                    {label: "Phone",        key: "PHONE1",      type: "phone"},
                    {label: "Address 1",    key: "ADDRESS1",    type: "text"},
                    {label: "Address 2",    key: "ADDRESS2",    type: "text"},
                    {label: "City",         key: "CITY",        type: "text"},
                    {label: "State",        key: "STATE",       type: "text"},
                ],
                data: null,
                loading: true
            },
            rows: [
              [
                {label: "GA Issued",      key:"GA_ISS_DT"},
                {label: "Isued Date",     key:"SUB_ISS_DT"},
                {label: "Issued",         key:"ISS_OFFICE"},
                {label: "Responsible",    key:"RES_OFFICE"},
                {label: "Executed",       key:"DATE_EXE"},
                {label: "Discharged",     key:"DISP_DT"},
                {label: "Forfeited",      key:"Y_FORFIT", inputType:"Boolean", color:"red"},
              ],
              [                
                {label: "GA Execution Reported",  key:"G_E_REP_DT"},
                {label: "Sub Execution Reported", key:"REPORT_DAT"},
                {label: "GA Discharge Reported",  key:"G_D_REP_DT"},
                {label: "Sub Discharge Reported", key:"S_D_REP_DT"},
              ],
              [                
                {label: "Amount",         key:"BOND_AMT"},
                {label: "Bond Fee",       key:"BOND_FEE"},
                {label: "Rewrite Pre",    key:"R_APRE"},
                {label: "Rewrite No",     key:"REWRITE_NO"},
                {label: "Rewrite Amount", key:"REW_AMT"}
              ],
              [                
                {label: "Case Number",    key:"CASENUMBER"},
                {label: "County",         key:"COUNTY"},
                {label: "Court",          key:"COURT"}
              ],
              [                
                {label: "Memo",           key:"MEMO",     inputType: "textArea"}
              ],
            ]
        }
    },
    watch: {
        customer (val) {
          this.customer.data = (val.dialog) ? this.customer.data :  null
        },    
        value: function(val) {
            console.log("bond: ", val)
            this.getBondInd()
        }
    },
    computed: {
        dispData: function () {
            return this.cleanData(this.value)
        },
        flattenedRows: function () {
            let rows = this.rows
            let flattened = []
            for (const row of rows) {
                flattened =  flattened.concat(row)
            }
            return flattened;
        }
    },
    created() {
      this.cleanData()  
      this.getBondInd()
    },
    methods: {
        cleanData(value) {
            let data = Object.assign({}, value);
            data.GA_ISS_DT      = (data.GA_ISS_DT != null)  ? this.formatDate(data.GA_ISS_DT ) : null
            data.SUB_ISS_DT     = (data.SUB_ISS_DT != null) ? this.formatDate(data.SUB_ISS_DT ) : null
            data.DATE_EXE       = (data.DATE_EXE != null)   ? this.formatDate(data.DATE_EXE ) : null
            data.DISP_DT        = (data.DISP_DT != null)    ? this.formatDate(data.DISP_DT ) : null
            data.G_E_REP_DT     = (data.G_E_REP_DT != null) ? this.formatDate(data.G_E_REP_DT ) : null
            data.REPORT_DAT     = (data.REPORT_DAT != null) ? this.formatDate(data.REPORT_DAT ) : null
            data.G_D_REP_DT     = (data.G_D_REP_DT != null) ? this.formatDate(data.G_D_REP_DT ) : null
            data.S_D_REP_DT     = (data.S_D_REP_DT != null) ? this.formatDate(data.S_D_REP_DT ) : null
            data.Y_FORFIT       = (data.Y_FORFIT == "1")    ? true : false

            for (const [key, value] of Object.entries(data)) {
                if (value == null) {
                    data[key]=''
                }
            }

            return data
        },
        openCustomer(cust) {
            console.log("customer", cust)
            this.customer.data=cust
            this.customer.dialog=true
        },
        async getBondInd () {
            console.log("getBondInd")
            this.customers.loading = true
            this.apiReadIndOnBond(this.value.ALPHA_PRE, this.value.POWER).then((response) => {
                this.customers.data = response.data
                this.customers.loading = false
            })
      },
    }
  }
</script>

<style>

</style>