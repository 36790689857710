<template>
    <v-container class="altRow" >
        <v-row v-for="(value, index) in value" :key="index" dense>
            <v-col>{{index}}</v-col>
            <v-col>{{value}}</v-col>
        </v-row>
    </v-container>
</template>

<script>
  export default {
    name: 'AllDataPanel',
    props: ['value'],
  }
</script>