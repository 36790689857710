<template>
    <table class = "mtable">
        <thead>
            <tr>
                <th v-for="header in headers" :key="header.key">{{header.label}}</th>
                <th v-if="crud"></th>
            </tr>
              <tr class="mb-0 pa-0" :class="(loading)? '':'invisible'">
                <td :colspan="colspan">
                <v-progress-linear
                  indeterminate
                  v-bind="loading"
                ></v-progress-linear>
              </td>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(row, rowIndex) in data" :key="rowIndex" v-on:click="$emit('rowClick', row)">
                <template v-for="bodyhead in headers">
                    <!-- FOR EDITABLE FIELDS -->
                    <template v-if="bodyhead.edit" >
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-if="bodyhead.type == 'date'" >
                        <input v-model="row[bodyhead.key]"  v-bind="bodyhead" @blur="$emit('edit',{row:row, key:bodyhead.key, value: row[bodyhead.key]})">
                      </td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'dateTime'">
                        <m-date-time-input v-model="row[bodyhead.key]" v-on:blurred="$emit('edit',{row:row, key:bodyhead.key, value: $event})"></m-date-time-input>
                      </td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'switch'">
                        <v-switch v-model="row[bodyhead.key]" ma-0 @change="$emit('edit',{row:row, key:bodyhead.key, value: (row[bodyhead.key] ? 1 : 0)})"></v-switch>
                      </td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'select'">
                        <select v-model="row[bodyhead.key]" @blur="$emit('edit',{row:row, key:bodyhead.key, value: row[bodyhead.key]})">
                          <option v-for="(option, optIndex) in bodyhead.options" :key="optIndex">{{option}}</option>
                        </select>
                      </td>                      
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else
                          contenteditable 
                          v-text="row[bodyhead.key]" 
                          @blur="onEdit({row:row, key:bodyhead.key}, $event)"
                          onclick="document.execCommand('selectAll',false,null)"
                      >
                      </td>
                    </template>
                    <!-- FOR NON EDITABLE FIELDS -->
                    <template v-else >
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-if="bodyhead.type == 'date'">{{formatDate(row[bodyhead.key])}}</td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'phone'">{{formatPhone(row[bodyhead.key])}}</td>                        
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'currency'">{{formatCurrency(row[bodyhead.key])}}</td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'flag'"> <v-icon v-if="row[bodyhead.key] == 1" small :color="bodyhead.color">mdi-flag</v-icon> <div v-else> &nbsp;</div> </td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else-if="bodyhead.type == 'button'"><v-btn x-small color="primary" v-bind="bodyhead" @click="$emit(bodyhead.emit, row)">button</v-btn></td>
                      <td v-bind="tdBind" :key="bodyhead.key" :data-label="bodyhead.label" v-else>{{row[bodyhead.key]}} </td>
                    </template>
                </template>
                <td v-if="crud" v-on:click="$emit('deleteRow', row)" style="vertical-align: center;">
                  <v-icon color="error" small>mdi-close-circle</v-icon>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr v-if="crud">
            <td :colspan="colspan" v-on:click="$emit('addRow')" v-on:keyup.enter="$emit('addRow')" tabindex="0" >
              <v-btn color="success" small pa-0 ma-0 block><v-icon dark>mdi-plus</v-icon></v-btn>
            </td>
          </tr>
        </tfoot>
        
    </table>
    

</template>

<script>
  import formatMixin from '../mixins/formatMixin'
import MDateTimeInput from './MDateTimeInput.vue'

  export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { MDateTimeInput },
    name: 'mtable',
    mixins: [ formatMixin],
    props: ['headers', 'data', 'value', 'crud', 'loading'],
    data () {
        return {
          tdBind:{
            class: "input"
          }
        }
    },
    computed: {
      colspan: function() {
        return (this.crud) ? this.headers.length +1 : this.headers.length
      }
    },
    watch: {
      value: function(val) {
        this.$emit('input', val)
      }
    },
    created() {
 
    },
    methods: {
      onDateTimeEdit (record) {
        console.log("ONDATETIMEEDIT", record)
      },
      onEdit(record, evt){
        console.log("onEdit", record)
        console.log("onEdit2", evt)
        var src = evt.target.innerText
        record= {
          ...record,
          value:src
        }
        console.log("mtable emitting", record)
        this.$emit('edit', record)
      }
    }
  }
</script>

<style>
Table,
TD,
TR {
	border-width: 0;
	text-align: center;
	white-space: pre;
  color: black;
}

.invisible{
  visibility: hidden;
}

/* mtable {
  width: 100%;
} */
.mtable .input .v-input {
  padding: 0;
  margin: 0;
}

.mtable .v-input__slot {
  margin: 0;
}
.mtable .v-messages {
  display: none
}

table input {
  border:none;
  width:100%;
  height:100%;
  font-family: Verdana, Helvetica, Arial, FreeSans, sans-serif;
  font-size:12px;
  padding: 0 4px 0 0px;
  margin: 0;
}

table input:focus { 
  /* border:2px solid #5292F7; */
  outline: none;
}

tr:nth-child(odd)	{ background-color:#d7e1ec; }
tr:nth-child(even)	{ background-color:#FFFFFF; }   

thead  th{
	border: 0;
	background-color: #074870;
	color: white;
  padding-left:5px;
  padding-right:5px;
}

tfoot td{

	color: white;
}

tbody td{
	vertical-align: top;	
  padding-left:5px;
  padding-right:5px;
}

tr:hover {          
	background-color: #AACEF4;
	cursor: pointer;
}
td:hover {          
	background-color: #AACEF4;
	cursor: pointer;
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { 
    border: 1px solid #ccc; 
    margin-bottom:.5em;
  }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
    text-align: left;
    padding-left: 1%; 
    width: 100%;

	}
	
	td:before { 
		/* Now like a table header */
    content: attr(data-label);
    background-color: #074870;
    float: left;
    color: white;
    text-align: left;


		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 40%; 
		padding-right: 10px; 
    padding-left: 1%; 
		white-space: nowrap;
    margin-right: 1%;
	}
	


	/*
	Label the data
	*/
	/* td:nth-of-type(1):before { content: "First Name"; }
	td:nth-of-type(2):before { content: "Last Name"; }
	td:nth-of-type(3):before { content: "Job Title"; }
	td:nth-of-type(4):before { content: "Favorite Color"; }
	td:nth-of-type(5):before { content: "Wars of Trek?"; }
	td:nth-of-type(6):before { content: "Secret Alias"; }
	td:nth-of-type(7):before { content: "Date of Birth"; }
	td:nth-of-type(8):before { content: "Dream Vacation City"; }
	td:nth-of-type(9):before { content: "GPA"; }
	td:nth-of-type(10):before { content: "Arbitrary Data"; } */
}

</style>