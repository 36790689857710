export default {
    state: {
        alerts: []
    },
    getters: {

    },
    mutations:{
        addAlert(state, settings) {
            state.alerts.push(settings)
        }
    },
    actions: {
        showAlert(context, inputSettings) {

            var settings = {
                show: true,
                type: "success",
                dense: true,
                dismissible: true,
                message: "",
                elevation: "2",
                htmMessage: "",

            }
            for (var key in inputSettings) { //change only the settings that were input everything else keep the same
                settings[key] = inputSettings[key]
            }
            console.log("showAlert()", settings)
            context.commit('addAlert', settings)
        }
    }
}