<template>
    <v-card>
        <v-tabs fixed-tabs>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab>Find Existing</v-tab>
            <v-tab>Add New</v-tab>
        
            <v-tab-item>
                <v-toolbar>
                    <v-text-field
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        solo
                        dense
                        v-model="search"
                        autocomplete="off"
                    ></v-text-field>
                </v-toolbar>
                <v-card-text>
                    <v-container >
                        <v-row >
                            <v-col cols="12" >
                                <m-table :headers="headers" :loading="loading" :data="customers" v-on:rowClick="selectExisting"></m-table>
                            </v-col> 
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-tab-item>
                    
            <v-tab-item>
                <v-card-text>
                    <v-container >
                        <v-row v-for="(row, rowIndex) in rows" :key="rowIndex" dense>
                            <v-col v-for="col in row" :key="col.key" v-bind="col">
                                <v-text-field v-model="newCustomer[col.key]" dense v-bind="col" filled></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        @click="addNew(newCustomer)"
                    >
                        Add New Customer
                    </v-btn>
                </v-card-actions>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
  import apiMixin from '../mixins/apiMixin'

  export default {
    name: 'MCustomerPicker',
    mixins: [ apiMixin],
    props: ['value'],
    data () {
        return {
            addExistingDisabled: false,
            rows: [
                [
                    {label: "Last Name",            key:"lastName",     md:6, sm: 6, cols:12},
                    {label: "First Name",           key:"firstName",    md:6, sm: 6, cols:12},
                ],
                [
                    {label: "DOB",                  key:"DOB",          md:6, sm: 6, cols:12,         type:"date"},
                ]       
            ],
            newCustomer: {},
            cSearch: null,
            headers: [
                {label: "Last Name",    key: "lastName",    type: "text"},
                {label: "First Name",   key: "firstName",   type: "text"},
                {label: "DOB",          key: "DOB",         type: "date"},
            ],
            customers: [],
            showCustomerData: false,
            existingCustomer: {},
            loading: false
        }
    },
    computed: {
        search: {
            get: function() {
                return this.cSearch
            },
            set: function(newValue) {
                if (this.timeout) clearTimeout(this.timeout)
                let that = this
                this.timeout = setTimeout(() => {
                    this.cSearch = newValue
                    that.getCustomers

                }, 300)
                
            }
        },
        selectedCustomer() {
            let ecust = this.existingCustomer
            return {
                "Last Name": ecust.lastName,
                "First Name": ecust.firstName,
                "DOB": ecust.DOB,
            }
        }
    },
    watch: {
        cSearch () {
            this.page = 1
            this.getCustomers()
        }
    },
    created() {

    },
    methods: {
        resetPicker() {
            this.customers= []
            this.showCustomerData= false
            this.existingCustomer= {}
            this.loading= false
            this.newCustomer= {}
            this.cSearch= null
            this.addExistingDisabled= false
        },
        selectExisting(customer) {
            this.$emit('customerSelect',customer)
            this.resetPicker()
        },
        async addNew(newCustomer) {
                let payload = {
                    table: 'mcustomer',
                    values: newCustomer
                }
                this.apiCreate(payload).then((response) => {
                    let customer = {
                        id: response.id
                    }
                    this.$emit('customerSelect',customer)
                })
                this.resetPicker()
        },
        async getCustomers () {
                console.log("getting applicattions")
                this.loading=true
                let rowCount = 50
                let offset = (this.page - 1) * rowCount
                var payload =  {
                    search: {
                        expression :"CONCAT( IFNULL(`lastName`,''),', ',IFNULL(`firstName`,''), ' ',IFNULL(`lastName`,''))",
                        pattern:  "%" + this.cSearch + "%"
                    },
                    table: "mcustomer",
                    offset: offset,
                    rowCount: rowCount
                }
                // this.data = await this.apiRead(payload)
                this.apiRead(payload).then((response) => {
                    console.log("got customers", response)
                    this.customers = response.data
                    this.loading=false
                })
            },
    }
  }
</script>

<style>
.invisible{
  visibility: hidden;
}
</style>