<template>
    <v-expansion-panels  >
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>view raw data</v-expansion-panel-header>
                <v-expansion-panel-content >
                    <v-container class="altRow" >
                        <v-row v-for="(value, index) in value" :key="index" dense>
                            <v-col>{{index}}</v-col>
                            <v-col>{{value}}</v-col>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>    
            </v-expansion-panel>
        </v-expansion-panels>
    </v-expansion-panels>
</template>

<script>
  export default {
    name: 'AllDataPanel',
    props: ['value'],
  }
</script>