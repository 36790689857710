

<template> 
  <div >
    <v-alert
      v-for="alert in alerts"  :key="alert.message"
      class="mb-0"
      v-bind="alert"
    >
    <span v-html="alert.message"></span>  
    </v-alert >
  </div>
</template>

<script>

//const { mapState } 
// TODO: FINISH ALERT 
  export default {
    data () {
      return {
      }
    },
    computed: {
      alerts () {
        console.log("recomputing alerts")
        // eslint-disable-next-line no-undef
        return this.$store.state.alerts.alerts
      }
    },

    methods: {
      // ...mapActions('alert', [

      // ])
    }
  }
</script>