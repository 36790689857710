export default {
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        formatCurrency(input) {
          let dollarUS  =  Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
  
          return dollarUS.format(input)
        },
        formatDate(input) {
          // console.log("date", input)
          if (input == null) {return ""}
          if (input.length < 19) { input = input + ' 00:00:00'}
          if (input) {
            let d = new Date(input)
            let month = d.getMonth() + 1;	// Month	[mm]	(1 - 12)
            let day = d.getDate();		// Day		[dd]	(1 - 31)
            let year = d.getFullYear();

            
            let returnDate = month + "/" + day + "/" + year
            // console.log("output", returnDate)
            return (returnDate == "NaN/NaN/NaN") ? "" : returnDate
          } else {
            return ""
          }
        },
        formatMysqlDTtoObj (mysql) {
          console.log("formatMysqlDTtoObj input", mysql)
          var t = mysql.split(/[- :]/);
          var d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
          console.log("formatMysqlDTtoObj output", d)
          return d
        },
        formatMysqlDtoObj(mysql) {
          // console.log("format mysql date to object input",mysql)
          const a = mysql.split("-", 3)
          const d = new Date(a[0], a[1] - 1, a[2])

          // console.log("format mysql date to object output",d)
          return d
        },
        formatDateTimeLocal(input) {
          if (input != null) {
            const d = this.formatMysqlDTtoObj(input)
            var isoStr = d.toISOString()
            return isoStr.substring(0, isoStr.length-1)
          } else {
            return null
          }
        },
        formatDTLocaltoMysql(input) {

          if (input != null) {
            console.log("formatDTLocaltoMysql", input)
            let date = input.slice(0,19).replace('T', ' ')
            return date
          }
          return null
        },
        formatGetDateMonth(d) {
          console.log("formatGetDateMonth", d)
          const months = ['January', 'February', 'March','April','May', 'June','July','August','September', 'October','November','December']
          
          return months[d.getMonth()]                                                         
        },
        formatPhone(input) {
          var cleaned = ('' + input).replace(/\D/g, '');
          var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
          }
          return null;
        },
        formatNumInWords(num) {
          const a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
          const b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

          if ((num = num.toString()).length > 9) return 'overflow';
          let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
          if (!n) return; var str = '';
          str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
          str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
          str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
          str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
          str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
          return str;
        },
        /**
         * Adds time to a date. Modelled after MySQL DATE_ADD function.
         * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
         * https://stackoverflow.com/a/1214753/18511
         * 
         * @param date  Date to start with
         * @param interval  One of: year, quarter, month, week, day, hour, minute, second
         * @param units  Number of units of the given interval to add.
         */
        formatDateAdd(date, interval, units) {
          if(!(date instanceof Date))
            return undefined;
          var ret = new Date(date); //don't change original date
          var checkRollover = function() { if(ret.getDate() != date.getDate()) ret.setDate(0);};
          switch(String(interval).toLowerCase()) {
            case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
            case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
            case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
            case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
            case 'day'    :  ret.setDate(ret.getDate() + units);  break;
            case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
            case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
            case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
            default       :  ret = undefined;  break;
          }
          return ret;
        },
        formatDateTimeZoneOffset(dateTime) {
          var offset = new Date().getTimezoneOffset();
          return this.formatDateAdd(dateTime, 'minute', offset)
        },

        //gets the time from a datetime object and returns just the time as a string offset with timezone
        formatTimeFromDT(dateTime) {
          return this.formatDateTimeZoneOffset(dateTime).toLocaleTimeString('en-US')
        }

        
    }
}