<template>
    <v-dialog v-model="dialog" max-width="1300" :fullscreen = "isMobile">
        <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        
        <v-toolbar-title>
            {{title}}</v-toolbar-title>
        </v-toolbar>
        <slot></slot>
    </v-dialog >
</template>


<script>

  export default {
    name: 'pageCard',
    props: {
        value: Boolean,
        title: String
    },
    components: {
      // eslint-disable-next-line vue/no-unused-components
      
    },
    mixins: [],
    data () {
        return {
            dialog: false,
        }
    },
    watch: {
        value(val) {
            this.dialog = val
        },
        dialog(val) {
            if (!val) {
                this.$emit("input", val)
            }
        }
    }
  }
</script>