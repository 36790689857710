<template>
  <v-container>

  </v-container>
</template>

<script>

    import appSearch from '../mixins/appSearchMixin'

  export default {
    name: 'HelloWorld',
    mixins: [appSearch],
    data: () => ({
      
    }),
    watch: {
      appSearch () {
          this.$router.push('customers')
      }
    },
  }
</script>
