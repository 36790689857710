<template>
  <v-app id="inspire">
    <updating-app v-model="updateExists"></updating-app>

    <template v-if="!updateExists">
    <logon v-model = "user"></logon>
    
    <template v-if="user != null">
      <v-navigation-drawer v-model="drawer" app>      
        <profile v-model="user"></profile>
        <main-menu></main-menu>
      </v-navigation-drawer>
      <v-img :src="require('@/assets/background.jpg')"  class="overflow-y-auto bgFixed bgColor" position="center center">
          <v-overlay absolute :value="overLoading" app>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-app-bar app>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
              <v-text-field
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                solo
                v-model="appSearch"
              ></v-text-field>
            <!-- <v-toolbar-title>Application</v-toolbar-title> -->
          </v-app-bar>

          <v-main class="overflow-y-auto">
            <alert></alert>
            <!--  -->
            <v-slide-y-transition hide-on-leave>
            <router-view ></router-view>
            </v-slide-y-transition>
          </v-main>
      </v-img>

    </template>
    </template>
    
  </v-app>
</template>

<script>

import MainMenu from './components/MainMenu.vue'
import Profile from './components/Profile.vue'
import Logon from './components/Logon.vue'
import Alert from './components/Alert.vue'
import updatingApp from './components/updatingApp.vue'


import appSearchMixin from './mixins/appSearchMixin'
import apiMixin from './mixins/apiMixin'
import overLoading from './mixins/overLoading'
import updateMixin from './mixins/updateMixin'




export default {
  name: 'App',
  components: {
    MainMenu,
    Alert,
    Logon,
    Profile,
    updatingApp
  },
  mixins: [appSearchMixin, overLoading, apiMixin, Profile, updateMixin],
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

  },
  data: () => ({
    drawer: false,
    user: null,

  }),
  watch: {
    user: function(val) {
      console.log("user changed", val)

      if (val.userName != null) {
        this.apiReadLastUpdate().then((response)=> {
            console.log("got last update",response)
             this.$store.dispatch("showAlert", {
                        type: "info",
                        color: "primary",
                        icon: "mdi-message-alert ",
                        message: "Last Updated from Suboffice: " + response.dataUpdate
                      })
          })
      }
    }
  },
  methods: {
    handleResize() {
      console.log("resize")
      if (window.innerWidth <= 760) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  }
};
</script>

<style>
.bgFixed .v-image__image--cover{
      background-attachment: fixed;
}
</style>
