<template>
      <v-sheet
        color="grey lighten-4"
        class="pl-4 pt-4 pb-0 pr-4"
        >
        
        <v-avatar
          class="mb-4"
          color="grey darken-1"
          size="64"
        ></v-avatar>
        
        <div>{{value.displayName}}</div>
        <a :href="apiRootURL + '?a=logout'">Logout</a>
        <div class="text-caption d-flex justify-end">version 0.53</div>
        </v-sheet>
</template>


<script>
  import apiMixin from '../mixins/apiMixin'

  export default {
    name: 'profile',
    mixins: [apiMixin],
    props: ['value'],
    data () {
        return {
        }
    },
    computed: {

    },
    watch: {
      value: function(val) {
        this.$emit('input', val)
      }
    },
    created() {
 
    },
    methods: {
    }
  }
</script>

