<template>
    <v-dialog v-model="value" persistent max-width="500">
        <v-card color="primary" dark>
            <v-card-text>
                New update available. updating...
                <v-progress-linear
                    :active="loading"
                    indeterminate
                    color="white"
                    class="ma-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>

    </v-dialog>

</template>

<script>

  export default {
    name: 'updatingapp',
    mixins: [ ],
    props: ['value'],
    data () {
        return {
            loading: true
        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    methods: {
    }
  }
</script>

<style>

</style>