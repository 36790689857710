<template>
<input type="datetime-local" v-model="dateTime" @blur="onBlur()">

</template>

<script>
  import formatMixin from '../mixins/formatMixin'

  export default {
    name: 'mdatetimeinput',
    mixins: [ formatMixin],
    props: ['value'],
    data () {
        return {
            MysqlDateTime: null
        }
    },
    computed: {
        dateTime: {
            get: function () {
                return this.formatDateTimeLocal(this.value)
            },
            set: function (newValue) {
                this.MysqlDateTime = this.formatDTLocaltoMysql(newValue)
                // this.$emit('input', this.MysqlDateTime)
            }
        },

    },
    watch: {
      value: function(val) {
        this.MysqlDateTime = val
        this.$emit('input', val)
      }
    },
    created() {
    },
    methods: {
        onBlur() {
            console.log("BLURRING", this.MysqlDateTime)
            // this.MysqlDateTime = this.formatDTLocaltoMysql(this.value)
            this.$emit('blurred', this.MysqlDateTime)
        }
    }
  }
</script>
