<template>
    <v-dialog v-model="value" max-width="500">
        <v-card color="primary" dark>
            <v-card-text>
                generating pdfs
                <v-progress-linear
                    :active="loading"
                    indeterminate
                    color="white"
                    class="ma-0"
                ></v-progress-linear>
            </v-card-text>
            <!-- <v-card-actions class="ma-0">
                <v-btn
                    text
                    @click="generatePDF();"
                    class="ma-0"
                >
                    createPDF
                </v-btn>
            </v-card-actions> -->
        </v-card>

    </v-dialog>
</template>

<script>
    import formatMixin from '../mixins/formatMixin'
    import { PDFDocument } from 'pdf-lib'

  export default {
    name: 'fillpdf',
    mixins: [ formatMixin],
    props: ['form', 'data', 'value'],
    data () {
        return {
            loading: true
        }
    },
    computed: {

    },
    watch: {
      value: function(val) {
        if (val) {
            this.generatePDF()
        }
        this.$emit('input', val)
      }
    },
    created() {
        this.generatePDF
    },
    methods: {
        async generatePDF(){
            let that=this
            const pdfDoc = await PDFDocument.create();
            for (const formData of this.data) {
                let newPage = await that.fillForm(formData)
                const [copiedPage] = await pdfDoc.copyPages(newPage, [0])

                pdfDoc.addPage(copiedPage)
            }

            const pdfBytes = await pdfDoc.save()

            const blob = new Blob([pdfBytes], { type: 'application/pdf' })
            const objectUrl = window.URL.createObjectURL(blob)
            window.open(objectUrl)
            this.value = false

        },
        // eslint-disable-next-line no-unused-vars
        async fillForm(formData) {
            const data = formData.data
            const formUrl = formData.form
            const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())
            const pdfDoc = await PDFDocument.load(formPdfBytes)
            const form = pdfDoc.getForm()
           
            let field = null
            let fontSize = 10 //this sets default font size
            const fields = form.getFields() //get the field names in the form
            fields.forEach(dfield => { //loop through and add the data
                const name = dfield.getName()
                if (data[name]) {
                    switch (data[name].type) {
                        case 'text':
                            field = form.getTextField(name)
                            if (Array.isArray(data[name].data)) {
                                field.setText(data[name].data.join('\n'))
                            } else {
                                field.setText(data[name].data)
                            }
                            field.setText(data[name].data)
                            fontSize = (data[name].fontSize) ? data[name].fontSize : fontSize
                            field.setFontSize(fontSize)
                            break;
                        case 'checkbox':
                            field = form.getCheckBox(name)
                            if (data[name].data) {
                                field.check()   
                            } else {
                                field.uncheck()
                            }
                            break;

                        }
                }
            })


            form.flatten();
            return pdfDoc
        },


    }
  }


</script>

<style>


</style>