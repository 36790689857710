export default {
    data() {
        return {

        }
    },
    computed: {
        appSearch: {
            get: function() {
                return this.$store.state.appSearch
            },
            set: function(newValue) {
                if (this.timeout) clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    this.$store.commit('updateAppSearch', newValue)
                }, 300)
                
            }
        }
    },
    methods: {

    }
}