import Vue from 'vue'
import Vuex from 'vuex'
import alerts from './alerts.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appSearch: "",
    overLoading: false,
    isMobile: false,
  },
  mutations: {
    updateAppSearch(state, value){
      state.appSearch = value
    },
    updateOverLoading(state, value) {
      state.overLoading = value
    },
    updateIsMobile(state, value) {
      state.isMobile=value
    }
  }, 
  actions: {
  },
  modules: {
    alerts,
  }
})
