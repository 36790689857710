<template>
    <v-dialog v-model="dialog" persistent max-width="500">
        <v-card color="primary" dark>
            <v-card-text>
                {{status}}
                <v-progress-linear
                    :active="loading"
                    indeterminate
                    color="white"
                    class="ma-0"
                ></v-progress-linear>
            </v-card-text>
            <v-card-actions class="ma-0">
                <v-btn
                    text
                    @click="openLoginURL();"
                    class="ma-0"
                >
                    Login
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>

</template>

<script>
  import apiMixin from '../mixins/apiMixin'

  export default {
    name: 'logon',
    mixins: [ apiMixin],
    props: ['value'],
    data () {
        return {
            dialog: true,
            account: null,
            status: null,
            loginURL: null,
            loading: true
        }
    },
    computed: {

    },
    watch: {
      value: function(val) {
        if (val) {
            this.dialog = false
            this.$emit('input', val)
        } else {
            this.dialog = true
            this.getLogin();
        }
      },
      account: function(val) {
          if (val.loginURL) {
              window.open(val.loginURL);
          }
      }
    },
    created() {
        // this.dialog=false
        this.status = "waiting for response from Microsoft"
        this.getLogin()
    },
    methods: {
        openLoginURL() {
            // window.open(this.loginURL,'_blank');
            window.location.href= this.loginURL
            this.loading=true
            this.listenForLogin()
        },
        async getLogin() {
            console.log("getLogin")
            this.status = "Checking Login Status"
            this.apiAuth().then((response) => {
                console.log("got login response", response)
                
                if (response.loginURL) {
                    this.status = "openning login page " + response.loginURL
                    this.loginURL = response.loginURL;
                    this.openLoginURL()
                    this.laoding= false
                } else {
                    this.value = response
                }
            })
        },
        async listenForLogin() {
            console.log("begin login listening")
            var that = this
            
            this.status = "waiting for response from Microsoft"
            var interval = setInterval(function() {
                if (!that.value) { 
                        that.apiAuth().then((response)=> {
                            console.log("Login check response", response)  
                            if (!response.loginURL) {
                                that.value = response
                            }
                        })
                }
                else { 
                    console.log("End Login Check interval")  
                    clearInterval(interval);
                }
                }, 1000);
            setTimeout(function( ) { 
                console.log("timeout")
                that.loading =  false
                that.status = 'timed out: click "RETRY LOGIN"'
                clearInterval( interval );    
            }, 30000)
        }
    }
  }
</script>

<style>

</style>