import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: true,
        themes: {
            light: {

                primary: '#074870',
                error: '#c71a1a',
                success: '#1c7007',
                bgColor: 'rgb(255, 0, 0, 0.2)'
            }
        }
    }
});
