import axios from "axios";

export default {
    data: () => ({
        // apiRootURL: "http://localhost:80/suboffice.mbb.bond/API/"
        apiRootURL: window.location.origin + "/API/"
    }),
    created() {
        console.log("apiRootURL", this.apiRootURL)
    },
    methods: {
        async apiHandleError(response){
            let erMessage =''
            let error = ''
            if (!response.data.success) { 	
                if (response.data.error) {
                    error = response.data.error
                    erMessage = "<strong>" + error[0] + " - " + error[1] + " - " + error[2] + "</strong></br>" + response.data.query
                } else {erMessage = response.data}

                this.$store.dispatch("showAlert", {
                    type: "error",
                    message: erMessage
                })
            }    
        },
        async apiReadIndOnBond(pre, power) {
            let payload = {
                pre: pre,
                power: power
            }

            console.log("apiReadIndOnBond")
            var url = this.apiRootURL + 'index.php?a=readIndOnBond'
            let that = this
            return await axios.post(url, payload)
                .then((response) => {
                    that.apiHandleError(response)
                    console.log("apiRead response", response)    
                    return response.data
                }).catch(function(error){
                //error handling
                    throw error
                })
          },
        async apiReadBondsOnInd(customerNum) {

            let payload = {
                customerNum: customerNum
            }

            console.log("apiReadBondsOnInd")
            var url = this.apiRootURL + 'index.php?a=readBondsOnInd'
            let that=this
            return await axios.post(url, payload)
                .then((response) => {
                    that.apiHandleError(response)
          
                    console.log("apiRead response", response)    
    
                    return response.data
                }).catch(function(error){
                //error handling
                    throw error
                })
          },
      async apiRead(payload) {
        console.log("api Read")
        var url = this.apiRootURL + 'index.php?a=read'
        let that=this
        return await axios.post(url, payload)
            .then((response) => {
                that.apiHandleError(response)
         
                console.log("apiRead response", response)    

                return response.data
            }).catch(function(error){
            //error handling
                throw error
            })
      },
      async apiReadCollatRecpts(payload) {
        console.log("api Read")
        var url = this.apiRootURL + 'index.php?a=readCollatRecpts'
        let that=this
        return await axios.post(url, payload)
            .then((response) => {
                that.apiHandleError(response)
         
                console.log("apiRead response", response)    

                return response.data
            }).catch(function(error){
            //error handling
                throw error
            })
      },
      async apiDelete(payload) {
        console.log("api Read")
        var url = this.apiRootURL + 'index.php?a=delete'
        let that= this
        return await axios.post(url, payload)
            .then((response) => {
                that.apiHandleError(response)
          
                console.log("apiRead response", response)    

            }).catch(function(error){
            //error handling
                throw error
            })
      },
      async apiUpdate(payload) {
        console.log("apiUpdate payload", payload)
        let that=this
        var url = this.apiRootURL + 'index.php?a=update'
        return await axios.post(url, payload)
            .then((response) => {
                // eslint-disable-next-line no-undef
                that.apiHandleError(response)
                console.log("apiUpdate response", response)
                return response.data
            }).catch(function(error){
            //error handling
                throw error
            })
      },
      async apiCreate(payload) {
        let that=this
        var url = this.apiRootURL + 'index.php?a=create'
        return await axios.post(url, payload)
            .then((response) => {
                that.apiHandleError(response)
                console.log("apiCreate response", response)
                return response.data
            }).catch(function(error){
            //error handling
                throw error
            })
      },
      async apiAuth(){
        console.log("api Auth")
        var url = this.apiRootURL + 'index.php?a=auth'
        let that=this
        return await axios.post(url)
            .then((response) => {
                that.apiHandleError(response)
           
                console.log("apiRead response", response)    

                return response.data
            }).catch(function(error){
            //error handling
                throw error
            })
      },
      async apiReadLastUpdate(){
        console.log("api Read Last Update")
        var url = this.apiRootURL + 'index.php?a=readLastUpdate'
        let that=this
        return await axios.post(url)
            .then((response) => {
                that.apiHandleError(response)
          
                console.log("apiRead response", response)    

                return response.data
            }).catch(function(error){
            //error handling
                throw error
            })
      }
    }
  };