
<template>
  <v-card
    class="mx-auto"
    max-width="400"
  >
    <v-list>
      <v-list-item-group
        v-model="model"
        mandatory
        color="indigo"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="click(item.route)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          icon: 'mdi-account-group',
          text: 'Customers',
          route: 'customers'
        },
        {
          icon: 'mdi-cash',
          text: 'Bonds',
          route: 'bonds'
        },
        // {
        //   icon: 'mdi-clipboard-text-multiple ',
        //   text: 'Logs (Beta)',
        //   route: 'logs'
        // }
        {
          icon: 'mdi-printer',
          text: 'Print Packets',
          route: 'PrintPackets'
        }
      ],
      model: 1,
    }),
  methods: {
    click: function(path) {
        this.$router.push(path)
    }
  }

  }
</script>