import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import CustomerReport from './components/CustomerReport.vue'
import MTable from './components/MTable.vue'
import BondReport from './components/BondReport.vue'
import AllDataPanel from './components/AllDataPanel.vue'
import fillPdf from './components/fillPdf.vue'
import MDateTimeInput from './components/MDateTimeInput.vue'
import MCustomerPicker from './components/MCustomerPicker.vue'
import ListData from './components/ListData.vue'
import pageCard from './components/pageCard.vue'
import MDialog from './components/MDialog.vue'


Vue.config.productionTip = false
Vue.component('customer-report', CustomerReport)
Vue.component('bond-report', BondReport)
Vue.component('m-table', MTable)
Vue.component('all-data-panel', AllDataPanel)
Vue.component('fill-pdf', fillPdf)
Vue.component('m-date-time-input', MDateTimeInput)
Vue.component('m-customer-picker', MCustomerPicker)
Vue.component('list-data', ListData)
Vue.component('page-card', pageCard)
Vue.component('m-dialog', MDialog)





new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
