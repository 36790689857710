export default {
    data() {
        return {
            moFormat: {
                inlineFlex: true
            },
        }
    },
    created () {

    },
    computed: {
        overLoading: {
            get: function() {
                return this.$store.state.overLoading
            },
            set: function(newValue) {
                this.$store.commit('updateOverLoading', newValue)
            }
        },
        isMobile: {
            get: function() {
                return this.$store.state.isMobile
            },
            set: function(newValue) {
                this.$store.commit('updateIsMobile', newValue)
            }
        }
    },
    methods: {
        calculateMoFormat() {
            if (this.isMobile) {
                this.moFormat = {
                    inlineFlex: false
                }
            } else {
                this.moFormat = {
                    inlineFlex: true
                }
            }
        }
    }
}